import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Logo from 'components/atoms/logo';
import { SnsIcon, SnsIconType } from 'components/atoms/snsicon';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import { mq, baseTransitionSecond, breakpoint } from 'style/variables';
import { FooterStudiosQuery } from 'types/graphql-types';
import { IndexLink } from 'components/molecules/indexLink';
import { useEffect, useState } from 'react';
import { useIsSp } from 'util/useIsSp';

const simpleFooterPages = ['/contact'];

type FooterProps = {
  locationPathname: string;
};
const isSimpleFooter = (props: FooterProps) => simpleFooterPages.some(item => props.locationPathname.includes(item));

const navs = [
  { name: 'Projects', link: '/projects' },
  { name: 'Services', link: '/services' },
  {
    name: 'About',
    link: '/about',
    subNavs: [
      { name: 'Company', link: '/about/company' },
      { name: 'Studios', link: '/about/studios' },
      { name: 'Vision', link: '/about/vision' },
      { name: 'Culture', link: '/about/culture' },
      { name: 'Sustainability', link: '/about/sustainability' },
    ],
  },
  { name: 'Topics', link: '/topics' },
  { name: 'Career', link: 'https://recruit.4digit.com/' },
  { name: 'Contact', link: '/contact' },
];

const Container = styled.footer<FooterProps>`
  position: relative;
  background: ${colors.black03};
  color: ${colors.white01};
  z-index: 1;
  ${mq.onlypc} {
    min-width: 1240px;
  }
`;

const FooterInner = styled('div')`
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 40px;
  ${mq.onlysp} {
    padding: 0 32px;
  }
`;

const FooterGroup = styled('div')`
  width: 100%;
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;
  ${mq.onlypc} {
    padding-top: 75px;
  }
  ${mq.onlysp} {
    padding-top: 32px;
    margin-bottom: 78px;
  }
`;

const FooterSubGroup = styled('ul')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  row-gap: 8px;
  ${mq.onlysp} {
    margin-top: 14px;
  }
`;

const FooterInfo = styled('div')`
  display: flex;
  position: relative;

  a {
    span {
      font-size: 12px;
      ${mq.onlysp} {
        letter-spacing: 0;
      }
    }
  }
  ${mq.onlypc} {
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: ${sizes.margin[24]};
  }
  ${mq.onlysp} {
    flex-direction: column;
    padding-bottom: 34px;
  }
`;
interface IsEnProps extends FooterProps {
  isEn?: boolean;
}
const FooterCopy = styled('small')<IsEnProps>`
  font-family: ${fonts.enGothic};
  font-size: 8px;
  letter-spacing: 0.07563em;
  line-height: 12px;
  opacity: 0.2;
  ${props =>
    !isSimpleFooter(props) &&
    css`
      margin-top: 12px;
    `};
  ${mq.onlypc} {
    ${props =>
      isSimpleFooter(props) &&
      css`
        position: absolute;
        top: 50%;
        right: 140px;
        transform: translateY(-50%);
      `};
  }
  ${mq.onlysp} {
    ${props =>
      isSimpleFooter(props)
        ? css`
            text-align: center;
            width: 100%;
            padding: ${sizes.margin[32]} 0 ${sizes.margin[16]};
          `
        : css`
            margin-top: 10px;
          `};
  }
`;

const FooterInfoLogo = styled('div')`
  height: 72px;
  svg {
    height: 100%;
  }
  ${mq.onlysp} {
    position: absolute;
    right: 0;
    order: 3;
    height: 76px;
  }
`;

const FooterLogo = styled('div')<FooterProps>`
  position: relative;
  height: 70px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mq.onlysp} {
    height: 80px;
    ${props =>
      isSimpleFooter(props) &&
      `
      flex-direction: column-reverse;
      height: auto;
      padding-bottom: 40px;
    `}
  }
`;

const FooterLogoInner = styled(Link)`
  display: block;
  width: 192px;
  svg {
    width: 100%;
  }
`;

const FooterPrivacyPolicy = styled(Link)`
  position: relative;
  font-family: ${fonts.enGothic};
  letter-spacing: 0.175em;

  ${text(12, 1)};
  ${mq.onlysp} {
    letter-spacing: 0.005em;
  }
`;

const FooterSnsIcons = styled.div`
  display: flex;
  justify-content: flex-end;

  ${mq.onlysp} {
    margin-top: ${sizes.margin[20]};
    justify-content: flex-start;
  }
`;

const FooterSnsIcon = styled(SnsIcon)`
  &:not(:last-of-type) {
    margin-right: 34px;
    ${mq.onlysp} {
      margin-right: 16px;
    }
  }
`;

const FooterNav = styled('ul')`
  display: grid;
  ${mq.onlypc} {
    letter-spacing: 0.1em;
    grid-template-columns: repeat(6, calc(640px / 6));
    column-gap: 15px;
  }
  ${mq.onlysp} {
    letter-spacing: 0;
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    gap: 24px 32px;
    padding-top: 32px;
    justify-content: flex-start;
  }
`;

const FooterNavItem = styled('li')`
  ${mq.onlypc} {
    display: flex;
    flex-direction: column;
  }
  ${mq.onlysp} {
    width: fit-content;
  }

  > a {
    position: relative;
    display: block;
    font-family: ${fonts.enGothic};

    font-size: 14px;
    line-height: 1.25em;
    ${mq.onlysp} {
      font-size: 16px;
      line-height: 19px;
    }

    ${mq.onlypc} {
      &:hover {
        &::before {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }
  }
  &.is-active {
    ${mq.onlypc} {
      > a {
        &:before {
          transform: scaleX(1);
          opacity: 0.5;
        }
      }
    }
  }
`;

const FooterSubNavItem = styled.li`
  > a {
    display: block;
    color: ${colors.white01};
    font-family: ${fonts.enGothic};
    white-space: nowrap;
    opacity: 0.5;
    transition: opacity ${baseTransitionSecond}s ease;

    ${mq.onlypc} {
      font-size: 12px;
      line-height: 1.16;
      &:hover {
        opacity: 1;
      }
    }
    ${mq.onlysp} {
      font-size: 14px;
      line-height: 1.2;
    }
  }
`;
const FooterInfoLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${mq.onlysp} {
    margin-top: 32px;
    order: 2;
  }
`;

const FooterInfoRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 0;

  ${mq.onlysp} {
    order: 1;
  }
`;

const Footer: React.FC<FooterProps> = props => {
  const studios: FooterStudiosQuery = useStaticQuery(
    graphql`
      query FooterStudios {
        shifterStudiosData(slug: { eq: "tokyo" }) {
          acf {
            sns {
              type
              url
            }
          }
        }
      }
    `
  );

  const [layout, setLayout] = useState<'pc' | 'sp'>('pc');
  const handleResize = () => {
    setLayout(window.innerWidth > breakpoint ? 'pc' : 'sp');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, false);
  }, [layout]);

  const isEn = props.locationPathname.includes('en');
  const copyright = 'Copyright ©FOURDIGIT Inc.';

  const isSp = useIsSp();

  return (
    <Container {...props}>
      {isSimpleFooter(props) ? (
        <FooterLogo {...props}>
          <FooterLogoInner to={`${isEn ? '/en' : ''}/`}>
            <Logo type="white-full" />
          </FooterLogoInner>
          <FooterCopy {...props} isEn={isEn}>
            {copyright}
          </FooterCopy>
        </FooterLogo>
      ) : (
        <FooterInner>
          <FooterGroup>
            <FooterNav>
              {navs.map((nav, index) => (
                <FooterNavItem key={index}>
                  {nav.link.match('http') ? (
                    <a href={nav.link} target="_blank" rel="noreferrer">
                      {nav.name}
                    </a>
                  ) : (
                    <IndexLink to={`${isEn ? '/en' : ''}${nav.link}`}>{nav.name}</IndexLink>
                  )}
                  {nav.subNavs && (
                    <FooterSubGroup>
                      {nav.subNavs?.map((subNav, index) => {
                        if (!isEn && subNav.name !== 'Company') return null;
                        return (
                          <FooterSubNavItem key={index}>
                            <IndexLink to={`${isEn ? '/en' : ''}${subNav.link}`}>{subNav.name}</IndexLink>
                          </FooterSubNavItem>
                        );
                      })}
                    </FooterSubGroup>
                  )}
                </FooterNavItem>
              ))}
            </FooterNav>
            {!isSp && studios?.shifterStudiosData?.acf?.sns && (
              <FooterSnsIcons>
                {studios?.shifterStudiosData?.acf?.sns.map((sns, idx) => {
                  if (layout === 'sp') {
                    if (idx <= 1) {
                      return (
                        <FooterSnsIcon key={sns?.type || idx} to={sns?.url || ''} name={sns?.type as SnsIconType} />
                      );
                    } else {
                      return '';
                    }
                  } else {
                    return <FooterSnsIcon key={sns?.type || idx} to={sns?.url || ''} name={sns?.type as SnsIconType} />;
                  }
                })}
              </FooterSnsIcons>
            )}
          </FooterGroup>
          <FooterInfo>
            <FooterInfoLeftColumn>
              <FooterPrivacyPolicy to={`${isEn ? '/en' : ''}/privacy/`}>Privacy Policy</FooterPrivacyPolicy>
              <FooterCopy {...props}>{copyright}</FooterCopy>
            </FooterInfoLeftColumn>
            <FooterInfoRightColumn>
              {isSp && studios?.shifterStudiosData?.acf?.sns && (
                <FooterSnsIcons>
                  {studios?.shifterStudiosData?.acf?.sns.map((sns, idx) => (
                    <FooterSnsIcon key={sns?.type || idx} to={sns?.url || ''} name={sns?.type as SnsIconType} />
                  ))}
                </FooterSnsIcons>
              )}
              {!isSp && (
                <FooterInfoLogo {...props}>
                  <Logo type="white-sq" />
                </FooterInfoLogo>
              )}
            </FooterInfoRightColumn>
            {isSp && (
              <FooterInfoLogo {...props}>
                <Logo type="white-sq" />
              </FooterInfoLogo>
            )}
          </FooterInfo>
        </FooterInner>
      )}
    </Container>
  );
};

export default Footer;
