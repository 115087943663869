import { css, keyframes } from '@emotion/core';
import { contentWidthPc } from 'style/variables';
import { mq } from './variables';
import sizes from 'style/sizes';
export const text = (fontSize: number, lineHeight: number, noNegative?: boolean) => {
  return css`
    font-size: ${fontSize}px;
    line-height: ${lineHeight};
    padding: 0.1px;
    /* text-transform: uppercase; */
    font-weight: 500;

    ${noNegative
      ? `margin-bottom: calc((${lineHeight} - 1) * ${fontSize}px / 2 * -1);
    margin-top: calc((${lineHeight} - 1) * ${fontSize}px / 2 * -1);`
      : `&:before,
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
    }
    &:before {
      margin-top: calc((1 - ${lineHeight}) * 0.5em);
    }
    &:after {
      margin-bottom: calc((1 - ${lineHeight}) * 0.5em);
    }`}
  `;
};

export const contentWrapper = css`
  margin: auto;
  box-sizing: content-box;
  ${mq.onlypc} {
    max-width: ${contentWidthPc}px;
    padding: 0 40px;
  }
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
  }
`;

export const scrollAnimeKeyFrame = keyframes`
  10% {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  40% {
    top: auto;
    bottom: 0;
    height: 0;
  }
  41% {
    bottom: auto;
    top: 0;
    height: 0;
  }
  70% {
    bottom: auto;
    top: 0;
    height: 100%;
  }
`;

export const scrollAnimePositionKeyFrame = keyframes`
  10% {
    top: auto;
    bottom: 0;
  }
  40% {
    top: auto;
    bottom: 0;
  }
  41% {
    bottom: auto;
    top: 0;
  }
  70% {
    bottom: auto;
    top: 0;
  }
`;

export const arrowHoverKeyFrame = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const arrowHoverKeyFramePrev = keyframes`
  0% {
    transform: translateX(0) scale(-1, 1);
  }
  50% {
    transform: translateX(-5px) scale(-1, 1);
  }
  100% {
    transform: translateX(0) scale(-1, 1);
  }
`;

export const scrollAnime = css`
  animation: ${scrollAnimeKeyFrame} 2.5s ease infinite;
`;

export const scrollAnimePosition = css`
  animation: ${scrollAnimePositionKeyFrame} 2.5s ease infinite;
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeup = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadeScaleUp = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  80% {
    transform: scale(1.05);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadeupBig = keyframes`
  0% {
    transform: translateY(90px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadedown = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
export const fadedownBig = keyframes`
  0% {
    transform: translateY(-90px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const slideIn = keyframes`
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  51% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
`;

export const slideInActive = keyframes`
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: transform: scaleX(1);
  }
`;
