import React from 'react';
import { Link } from 'gatsby';

type IndexLinkProps = {
  to: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const IndexLink: React.FC<IndexLinkProps> = props => {
  return <Link {...props}>{props.children}</Link>;
};
