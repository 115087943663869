import React, { useEffect } from 'react';
import Header from 'components/organisms/header';
import Footer from 'components/organisms/footer';
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import { globalStyle } from 'style/';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { mq, baseTransitionSecond } from 'style/variables';
import SEO from './util/seo/index';
import { ParallaxProvider } from 'react-scroll-parallax';

type DefaultLayoutProps = {
  location: {
    pathname: string;
  };
  children: any;
};

const Main = styled('main')`
  ${mq.onlypc} {
    min-width: 1240px;
  }
`;

const TransitionCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  height: 100vh;
  background-color: #ecedee;
  opacity: 0;
  pointer-events: none;
  .is-exiting & {
    opacity: 1;
    transition: opacity ${baseTransitionSecond}s ease;
  }
`;

const DefaultLayout: React.FC<DefaultLayoutProps> = props => {
  useEffect(() => {
    document.body.classList.add('is-loaded');
  }, []);
  return (
    <>
      <SEO locationPathName={props.location.pathname} />
      <Global styles={globalStyle} />
      <Header locationPathname={props.location.pathname} />
      <ParallaxProvider>
        <Main>{props.children}</Main>
      </ParallaxProvider>
      <Footer locationPathname={props.location.pathname} />
      <TransitionCover />
    </>
  );
};

export default DefaultLayout;
