require('babel-polyfill');
require('@fourdigit/sanitize-4d.css');

export function onClientEntry() {
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`);
  }

  /**
   * TODO
   * 処理を別ファイルに分けたいがtsで作るとそのままでは使えないのでgatsby-browser.tsでも動くようにする必要がある。
   * タブレット時はviewportをpc最小幅に設定したい。
   * uaでsp(iphone 5sだけみる？)とtabだけこの処理を追加したい。
   */

  const ua = window.navigator.userAgent.toLowerCase();
  const isiPad = ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document);

  const windowMinWidth = 375;
  function setViewport() {
    const $viewport = document.querySelector('meta[name="viewport"]');
    if (window.parent.screen.width < windowMinWidth) {
      $viewport.setAttribute('content', 'width=375,user-scalable=no,shrink-to-fit=yes');
    } else if (isiPad) {
      $viewport.setAttribute('content', 'width=1280,user-scalable=no,shrink-to-fit=yes');
    } else {
      $viewport.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no');
    }
  }
  let timeoutId;
  function handlesetViewport() {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(function () {
      setViewport();
    }, 50);
  }
  setViewport();
  window.addEventListener('resize', handlesetViewport);
}
