import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { breakpoint } from 'style/variables';

export const useIsSp = () => {
  const [isSp, setIsSp] = useState(false);

  const spJudgment = useCallback(() => {
    setIsSp(window.innerWidth <= breakpoint);
  }, []);

  const handlespJudgment = debounce(spJudgment, 16);

  useEffect(() => {
    spJudgment();
    window.addEventListener('resize', handlespJudgment);
    return () => {
      window.removeEventListener('resize', handlespJudgment);
    };
  }, []);

  return isSp;
};
