import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import iconBlankWhite from 'assets/images/icons/icon-blank-white.svg';
import iconBlank from 'assets/images/icons/icon-blank.svg';
import logoAnimation from 'assets/motions/header-logo.json';
import { IndexLink } from 'components/molecules/indexLink';
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import sizes from 'style/sizes';
import { baseTransitionSecond, isIe, mq, maxWidthPC, breakpoint } from 'style/variables';
import { useIsSp } from 'util/useIsSp';

const ClassNames = require('classnames');
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const navs = [
  { name: 'Projects', link: '/projects' },
  { name: 'Services', link: '/services' },
  { name: 'About', link: '/about' },
  { name: 'Topics', link: '/topics' },
  { name: 'Career', link: 'https://recruit.4digit.com/' },
  { name: 'Contact', link: '/contact' },
];

type HeaderProps = {
  locationPathname: string;
};

const langs = ['jp', 'en'];

interface IsJaAboutProps {
  isJaAbout?: boolean;
}

const HEADER_MIN_WIDTH = '1240px';

const Container = styled('header')<IsJaAboutProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  @media (max-width: ${HEADER_MIN_WIDTH}) and (min-width: ${breakpoint + 1}px) {
    position: sticky;
    height: 0;
  }
`;

const HeaderInner = styled('div')<IsJaAboutProps>`
  height: ${sizes.header.height.pc};
  align-items: center;
  display: flex;
  justify-content: space-between;

  transition: 0.5s 0s cubic-bezier(0.33, 0, 0.24, 1); // ヘッダー出現時
  transition-property: transform;
  will-change: transform;
  background-color: #fff;
  isolation: isolate;

  &.is-scrollDown {
    transform: translate3d(0, -100%, 0);
    transition: 0.33s 0.58s cubic-bezier(0.73, 0, 0.83, 1); // ヘッダー退去時
  }

  ${mq.onlypc} {
    max-width: ${maxWidthPC}px;
    min-width: ${HEADER_MIN_WIDTH};
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px;
  }
  ${mq.onlysp} {
    max-width: 100vw;
    height: ${sizes.header.height.sp};
    padding: 0 0 0 25px;
  }
  ${isIe} {
    background-color: rgba(255, 255, 255, 0.7);
    [data-page='top'].is-pagetop & {
      background-color: transparent;
    }
    ${({ isJaAbout }) =>
      isJaAbout
        ? css`
            background-color: unset;
          `
        : null}
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.7;
    ${({ isJaAbout }) =>
      isJaAbout
        ? css`
            background-color: unset;
          `
        : null}
    [data-page='top'].is-pagetop & {
      display: none;
    }
    ${isIe} {
      display: none;
    }
  }
`;

const HeaderLogo = styled(Link)`
  position: relative;
  z-index: 100;
  svg {
    width: 100%;
  }
  ${mq.onlysp} {
    width: 44px;
  }
`;

const HeaderLogoText = styled('div')`
  position: fixed;
  top: 38px;
  left: 40px;
  display: block;
  width: 150px;
  svg > * {
    ${Array.from(
      { length: 10 },
      (_, i) => `
  &:nth-child(${i + 1}) {
    transition: transform 0.5s calc(0.42s + 0.03s * ${i + 1}) cubic-bezier(0.33, 0.00, 0.24, 1.00); // ヘッダー出現時
    .is-scrollDown & {
     transition: transform 0.33s calc(0.03s * ${i + 1}) cubic-bezier(0.73, 0, 0.83, 1); // ヘッダー退去時
      transform: translate3d(0, 25px, 0);
    }
  }
`
    ).join('')}
  }

  @media (max-width: ${HEADER_MIN_WIDTH}) and (min-width: ${breakpoint + 1}px) {
    position: absolute;
  }

  ${mq.onlysp} {
    top: 24px;
    left: 32px;
    transition: opacity ${baseTransitionSecond}s ease;
    .is-menuOpened & {
      opacity: 0;
    }
  }
`;

const HeaderLogoSymbol = styled('div')`
  position: fixed;
  bottom: calc(100% - 76px);
  left: 40px;
  display: block;
  width: 48px;
  height: 0;
  overflow: hidden;
  transition: height 0.33s 0.25s cubic-bezier(0.73, 0, 0.83, 1); // ヘッダー出現時
  will-change: height;

  &.is-scrollDown {
    height: 48px;
    transition: height 0.33s 0.25s cubic-bezier(0.33, 0, 0.24, 1); // ヘッダー退去時
  }

  @media (max-width: ${HEADER_MIN_WIDTH}) and (min-width: ${breakpoint + 1}px) {
    position: absolute;
  }

  ${mq.onlysp} {
    bottom: calc(100% - 52px);
    left: 32px;
    width: 40px;
    &.is-scrollDown {
      height: 40px;
    }
  }

  .HeaderLogoSymbol_Lottie {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 48px;
    height: 48px;
    ${mq.onlysp} {
      width: 40px;
      height: 40px;
    }
  }
`;

const HeaderInnerRight = styled('div')`
  width: 100%;
  display: flex;
  position: relative;
`;

const HeaderNav = styled('nav')`
  ${mq.onlysp} {
    position: relative;
  }
`;

const HeaderNavList = styled('ul')`
  ${mq.onlypc} {
    display: flex;
    height: 100%;
  }
`;

const HeaderNavItem = styled('li')`
  ${mq.onlypc} {
    display: flex;
    align-items: center;
  }
  ${mq.onlysp} {
    margin-bottom: 41px;
  }
  &:not(:last-child) {
    ${mq.onlypc} {
      margin-right: 41px;
    }
  }
  a {
    position: relative;
    /* text-transform: uppercase; */
    font-weight: 500;
    display: block;
    font-family: ${fonts.enGothic};

    font-size: 14px;
    ${mq.onlysp} {
      font-size: 3.2rem;
      line-height: normal;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${colors.black01};
      margin: auto;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform ${baseTransitionSecond * 1.5}s ease, opacity ${baseTransitionSecond}s ease;
    }
    ${mq.onlypc} {
      &:hover {
        &::before {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }

    &[target='_blank'] {
      &::after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 9px;
        background-image: url(${iconBlank});
        background-size: contain;
        margin-left: 4px;
        ${mq.onlysp} {
          width: 14px;
          height: 16px;
          margin-left: 8px;
          background-image: url(${iconBlankWhite});
        }
      }
    }
  }
  &.is-active {
    ${mq.onlypc} {
      a {
        &:before {
          transform: scaleX(1);
          opacity: 0.5;
        }
      }
    }
  }
`;

const HeaderLangList = styled('div')`
  ${mq.onlypc} {
    height: 30px;
    margin-left: 38px;
  }
  &.is-mouseenter {
    ${mq.onlypc} {
      & > ul {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  & > ul {
    ${mq.onlypc} {
      position: relative;
      margin-top: -7px;
      padding: 0 9px 15px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #e6e9e9;
        transition: opacity ${baseTransitionSecond}s ease;
        opacity: 0;
      }
    }
    ${mq.onlysp} {
      display: flex;
      position: relative;
    }
  }
`;

const HeaderLangItem = styled('li')`
  ${mq.onlypc} {
    text-align: center;
    font-size: 1.3rem;
    line-height: 2;
    & + & {
      margin-top: 10px;
    }
  }
  ${mq.onlysp} {
    & + li {
      &::before {
        content: '/';
        font-size: 2rem;
        display: inline-block;
        padding: 0 1em;
      }
    }
  }
  &:not(.is-active) {
    ${mq.onlypc} {
      opacity: 0;
      transform: translate3d(0, -0.3em, 0);
      transition: opacity ${baseTransitionSecond}s ease, transform ${baseTransitionSecond}s ease;
    }
    .is-mouseenter & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

const HeaderLangLink = styled(Link)`
  position: relative;
  font-family: ${fonts.enGothic};

  font-size: 16px;
  text-transform: uppercase;
  transition: color ${baseTransitionSecond}s ease;
  color: ${colors.white01};
  ${mq.onlypc} {
    display: block;
    padding-bottom: 6px;
    .is-active > & {
      &::before {
        left: 0;
        right: 0;
        bottom: 2px;
        width: 0;
        height: 0;
        margin: auto;
        border-style: solid;
        border-width: 4px 10px 0 10px;
        border-color: currentColor transparent transparent transparent;
        transition: border-color ${baseTransitionSecond}s ease;
      }
    }
    &:hover {
      color: ${colors.white01};
    }
  }
  ${mq.onlysp} {
    font-size: 2rem;
    .is-active > & {
      &::after {
        width: 100%;
        transform-origin: left;
        transform: scale3d(1, 1, 1);
      }
    }
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
  }
`;

const HeaderBtn = styled('button')`
  ${mq.onlypc} {
    display: none;
  }
  ${mq.onlysp} {
    position: relative;
    width: 80px;
    height: ${sizes.header.height.sp};
    margin: 0 0 0 auto;
    z-index: 9999;

    &:hover {
      outline: 0;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 24px;
      margin: auto;
      background-color: ${colors.black01};
      .is-menuOpened & {
        background-color: ${colors.white01};
      }
    }
    &::before {
      width: 24px;
      height: 2px;
      top: -6px;
      bottom: 0;
      transition: transform ${baseTransitionSecond}s ease;
      .is-menuOpened & {
        top: 2px;
        left: 0;
        right: 0;
        width: 24px;
        transform: rotate3d(0, 0, 1, 145deg);
      }
    }
    &::after {
      width: 12px;
      height: 2px;
      top: 6px;
      bottom: 0;
      transition: ${baseTransitionSecond / 2}s, width ${baseTransitionSecond / 2}s ease ${baseTransitionSecond / 2}s,
        transform ${baseTransitionSecond / 2}s ease;
      .is-menuOpened & {
        top: 2px;
        left: 0;
        right: 0;
        width: 24px;
        border-radius: 0;
        transform: rotate3d(0, 0, 1, 35deg);
        transition: height ${baseTransitionSecond / 2}s ease, width ${baseTransitionSecond / 2}s ease,
          transform ${baseTransitionSecond / 2}s ease ${baseTransitionSecond / 2}s;
      }
    }
  }
`;

const HeaderMenu = styled.div`
  width: 100%;
  ${mq.onlysp} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 8000;
    color: ${colors.white01};
    opacity: 0;
    pointer-events: none;
    transition: opacity ${baseTransitionSecond}s ease;
    overflow: scroll;
    background-color: ${colors.black02};
    -webkit-overflow-scrolling: touch;
    .is-menuOpened & {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

const HeaderMenuInner = styled.div`
  ${mq.onlypc} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ${mq.onlysp} {
    position: relative;
    padding: ${sizes.margin[100]} ${sizes.margin[32]} ${sizes.margin[180]};
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors.black02};
    }
  }
`;

const Header: React.FC<HeaderProps> = props => {
  const headerMenu = useRef<HTMLDivElement>(null);
  const [directory, setDirectory] = useState('');
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isMouseEnter, setisMouseEnter] = useState(false);
  const [langState, setLangState] = useState('jp');
  const isSp = useIsSp();
  const [isScrollDown, setIsScrollDown] = useState(false);
  const logoLottieRef = useRef<LottieRefCurrentProps | null>(null);
  let scrollPos = 0;
  const isJaAbout = !props.locationPathname.includes('/en') && props.locationPathname.endsWith('/about');

  const scrollHandler = useCallback(e => {
    const tmpIsScrollDown = scrollPos < 100 ? false : scrollPos < window.scrollY;
    setIsScrollDown(tmpIsScrollDown);
    scrollPos = window.scrollY;
  }, []);

  const toggleClass = useCallback((mouseEnter: boolean) => {
    setisMouseEnter(mouseEnter);
  }, []);

  const scrollLock = (e: Event) => {
    e.preventDefault();
  };

  const menuBtnHandler = () => {
    if (!isSp) return;
    headerMenu.current?.scrollTo(0, 0);
    if (!isMenuOpened) {
      disableBodyScroll(headerMenu.current);
    } else {
      enableBodyScroll(headerMenu.current);
    }
    setIsMenuOpened(!isMenuOpened);
  };

  useEffect(() => {
    const path = location.pathname.split('/');
    setDirectory(langState === 'en' ? path[2] : path[1]);
    setLangState(path.includes('en') ? 'en' : 'jp');
  });
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      enableBodyScroll(headerMenu.current);
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);
  useEffect(() => {
    if (logoLottieRef.current && isScrollDown) {
      logoLottieRef.current.goToAndPlay(0);
    }
  }, [isScrollDown]);

  const classNames = ClassNames({
    'is-menuOpened': isMenuOpened,
    'is-scrollDown': isScrollDown,
  });

  return (
    <Container className={classNames} isJaAbout={isJaAbout}>
      <HeaderLogo to={langState === 'jp' ? '/' : '/en'}>
        <HeaderLogoText className={classNames}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 20">
            <path
              aria-label="F"
              fill="#1A1311"
              d="M2.5,5v3.7H0.7v3.9h1.8v4.5c-0.1,0.9-0.2,2.1-0.8,2.6v0.1h5.2c-0.1-2-0.2-4.5-0.2-7.3h5.8V8.7H6.7
	c0-1.7,0.1-3.2,0.2-4.6h9.6v-4H2.4C2.4,1.7,2.5,3.4,2.5,5z"
            />
            <path
              aria-label="O"
              fill="#1A1311"
              d="M27.7,0c-5.6,0-10.1,4.5-10.1,10s4.5,10,10.1,10c5.6,0,10.1-4.5,10.1-10C37.9,4.5,33.3,0,27.7,0z M27.7,16
	c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S31,16,27.7,16z"
            />
            <path
              aria-label="U"
              fill="#1A1311"
              d="M52,10.9c0,1.1,0,2.9-0.6,3.7c-0.5,0.7-1.4,1.2-2.2,1.3h-1.4c-0.9-0.1-1.7-0.6-2.2-1.3C45,13.8,45,12,45,10.9
	V0.1h-4.1v12.3c0,1.2,0.1,2.4,0.7,3.5c0.5,1.1,1.3,2,2.3,2.7c1,0.7,2.2,1.1,3.4,1.2h2.6c1.2-0.1,2.4-0.5,3.4-1.2s1.8-1.6,2.3-2.7
	s0.7-2.3,0.7-3.5V0.1H52C52,0.1,52,10.9,52,10.9z"
            />
            <path
              aria-label="R"
              fill="#1A1311"
              d="M70.5,13.3c2.4-1.2,4-3.6,4-6.3c0-3.8-3.1-6.8-6.9-6.8h-8v19.7h3.8v-6.1h2.7l4.3,6.1h4.8v-0.1L70.5,13.3z
	 M70.4,7.4c-0.1,0.9-0.6,1.7-1.4,2.1C68.1,10,67,10,66,10h-2.7V3.9H67c0.8,0,1.6,0.2,2.2,0.6C70.2,5.2,70.6,6.3,70.4,7.4z"
            />
            <path
              aria-label="D"
              fill="#1A1311"
              d="M84.7,0.1h-7.6v0.1C77.8,0.7,78,1.9,78,2.8c0.1,2.4,0.1,4.8,0.1,7.2s0,4.8-0.1,7.2c-0.1,0.9-0.2,2.1-0.8,2.5
	v0.1h7.6c5.1,0,9.3-4.2,9.3-9.3V9.4C94.1,4.3,89.9,0.1,84.7,0.1z M89.9,10.4c0,3.1-2.5,5.6-5.5,5.7h-2.3c-0.1-1.7-0.2-3.7-0.2-6.1
	s0.1-4.3,0.2-6.1h2.3c3,0.1,5.5,2.6,5.5,5.7V10.4z"
            />
            <rect aria-label="I" x="97.1" y="0.1" fill="#1A1311" width="4" height="19.7" />
            <path
              aria-label="G"
              fill="#1A1311"
              d="M124.3,8.8l-7.6,0.5V13l2.8-0.2c-1,2-3.1,3.4-5.6,3.2c-3.5-0.2-5.8-3.1-5.6-6.4c0.2-3.3,3.1-5.8,6.4-5.5
	c2,0.1,3.6,1.2,4.5,2.8l4.4-0.4c-1.5-4-5.6-6.7-10.1-6.4c-5.6,0.4-9.8,5.2-9.4,10.7s5.2,9.7,10.8,9.3c5.6-0.4,9.7-5.2,9.4-10.7
	L124.3,8.8L124.3,8.8z"
            />
            <rect aria-label="I" x="127.2" y="0.1" fill="#1A1311" width="4" height="19.7" />
            <polygon
              aria-label="T"
              fill="#1A1311"
              points="134,0.1 134,4.1 139.4,4.1 139.4,19.9 143.5,19.9 143.5,4.1 148.8,4.1 148.8,0.1 "
            />
          </svg>
        </HeaderLogoText>
        <HeaderLogoSymbol className={classNames}>
          <Lottie
            className={'HeaderLogoSymbol_Lottie'}
            animationData={logoAnimation}
            lottieRef={logoLottieRef}
            loop={false}
            autoPlay={false}
          />
        </HeaderLogoSymbol>
      </HeaderLogo>

      <HeaderInner className={classNames} isJaAbout={isJaAbout}>
        <HeaderInnerRight>
          <HeaderMenu ref={headerMenu}>
            <HeaderMenuInner>
              <HeaderNav>
                <HeaderNavList>
                  {navs.map(nav => (
                    <HeaderNavItem
                      key={nav.name}
                      className={nav.link.indexOf(directory, 0) > 0 ? 'is-active' : undefined}
                    >
                      {nav.link.match('http') ? (
                        <a href={nav.link} target="_blank">
                          {nav.name}
                        </a>
                      ) : (
                        <IndexLink
                          onClick={() => menuBtnHandler()}
                          to={`${langState === 'en' ? '/en' : ''}${nav.link}`}
                        >
                          {nav.name}
                        </IndexLink>
                      )}
                    </HeaderNavItem>
                  ))}
                </HeaderNavList>
              </HeaderNav>
              <HeaderLangList
                className={isMouseEnter ? 'is-mouseenter' : undefined}
                onMouseEnter={() => toggleClass(true)}
                onMouseLeave={() => toggleClass(false)}
              >
                {isSp ? (
                  <ul>
                    {langs.map(lang => (
                      <HeaderLangItem className={lang === langState ? 'is-active' : undefined} key={lang}>
                        <HeaderLangLink onClick={() => menuBtnHandler()} to={`/${lang !== 'jp' ? lang : ''}`}>
                          {lang}
                        </HeaderLangLink>
                      </HeaderLangItem>
                    ))}
                  </ul>
                ) : (
                  <ul>
                    <HeaderLangItem className="is-active">
                      <HeaderLangLink to={langState === 'jp' ? '/' : `/${langState}`}>{langState}</HeaderLangLink>
                    </HeaderLangItem>
                    {langs
                      .filter(item => {
                        return item !== langState;
                      })
                      .map((lang, idx) => (
                        <HeaderLangItem key={idx}>
                          <HeaderLangLink to={`/${lang !== 'jp' ? lang : ''}`}>{lang}</HeaderLangLink>
                        </HeaderLangItem>
                      ))}
                  </ul>
                )}
              </HeaderLangList>
            </HeaderMenuInner>
          </HeaderMenu>
          <HeaderBtn aria-label="menu button" onClick={() => menuBtnHandler()} />
        </HeaderInnerRight>
      </HeaderInner>
    </Container>
  );
};

export default Header;
