// グローバルCSS
import { css } from '@emotion/core';
import fonts from './fonts';
import { mq } from './variables';
import UnboundedBold from 'assets/fonts/Unbounded-Bold.woff2';
import UnboundedMedium from 'assets/fonts/Unbounded-Medium-4D.woff2';

export const globalStyle = css`
  @font-face {
    font-family: 'Unbounded-custom';
    src: url(${UnboundedBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Unbounded-custom';
    src: url(${UnboundedMedium}) format('woff2');
    font-weight: medium;
    font-style: normal;
  }

  html {
    /* 10px */
    font-size: 62.5%;
    font-family: ${fonts.enGothic}, ${fonts.jaGothic};
    color: #333;
  }

  body {
    background-color: #fff;
    font-feature-settings: 'palt';
    letter-spacing: 0.02em;
    line-height: 2;
    ${mq.onlypc} {
      font-size: 1.5em;
    }
    ${mq.onlysp} {
      font-size: 1.2em;
    }
    &.is-noscroll {
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 600;
    /* text-transform: uppercase; */
  }

  a {
    color: inherit;
    text-decoration: none;
    &:link,
    &:visited,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  p {
    margin: 0;
  }

  img {
    max-width: 100%;
    vertical-align: bottom;
    width: 100%;
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #808080;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #808080;
  }

  ::placeholder {
    color: #808080;
    /* Others */
    line-height: 1;
    white-space: nowrap;
  }

  button {
    /* reset */
    appearance: none;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0;
    &:active,
    &:focus {
      outline: 0;
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  dl {
    margin: 0;
  }

  dt {
  }

  dd {
    margin: 0;
    padding: 0;
  }

  br {
    ${mq.onlypc} {
      &.only-sp {
        display: none;
      }
    }
    ${mq.onlysp} {
      &.only-pc {
        display: none;
      }
    }
  }
`;

// export default globalStyle;
