import { css } from '@emotion/core';

// TODO 既存のままなので設定しなおす
export const baseWidthPC = 1440;
export const baseWidthTablet = 768;
export const baseWidthSP = 375;
export const breakPointTablet = 1079;
export const breakPointSP = 719;
export const contentWidthPc = 1160;
export const innerContentWidthPc = 700;
export const maxWidthPC = 1920;

export const breakpoint = 768;

export const baseTransitionSecond = 0.155;
export const easeIn = 'cubic-bezier(.86,.09,.87,.76)';
export const easeOut = 'cubic-bezier(.4,.59,.41,.97)';
export const easeOut02 = 'cubic-bezier(0,.05,0,1)';

export const headerHeightPc = 108;
export const headerHeightSp = 80;

export const mq = {
  overpc: `@media (min-width: ${baseWidthPC + 1}px)`,
  onlypc: `@media (min-width: ${breakpoint + 1}px)`,
  onlypcmin: `@media (max-width: ${contentWidthPc}px) and (min-width: ${breakpoint + 1}px)`,
  onlysp: `@media (max-width: ${breakpoint}px)`,
};
export const isIe = '@media all and (-ms-high-contrast: none)';

const CSS_VALUE_PRECISION = 2;

export function liquidSizePC(size: number): string {
  return `${((size / baseWidthPC) * 100).toFixed(CSS_VALUE_PRECISION)}vw`;
}

export function liquidSizeTablet(size: number): string {
  return `${((size / baseWidthTablet) * 100).toFixed(CSS_VALUE_PRECISION)}vw`;
}

export function liquidSizeSP(size: number): string {
  return `${((size / baseWidthSP) * 100).toFixed(CSS_VALUE_PRECISION)}vw`;
}

export const customMq = (props: number) => `@media (min-width: ${props + 1}px)`;

export const grayscaleFilter = `brightness(0.9) contrast(100%) grayscale(100%)`;
export const grayscaleFilterSimple = `brightness(1) grayscale(100%)`;
export const grayscaleFilterTop = `brightness(1) contrast(100%) grayscale(0%)`;
export const grayscaleFilterSimpleTop = `brightness(1) grayscale(100%)`;
