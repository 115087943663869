const colors = {
  black01: '#000000',
  gray01: '#888888',
  gray02: '#e8e8e8',
  gray03: '#fafafa',
  gray04: '#d8d8d8',
  gray06: '#c5c5c5',
  gray07: '#878f91',
  white01: '#ffffff',

  /** -- NOTE: 202406 Rbrの際に追加された新規色 -- */
  black02: '#31312B',
  black03: '#191210',
  white02: '#fcfcfc',
  gray08: '#f4f4f4',
  gray09: '#d9d9d9',
};

export default colors;
