import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from 'assets/images/seo/favicon.svg';
import faviconApple from 'assets/images/seo/apple-touch-icon.png';
import faviconSafari from 'assets/images/seo/safari-pinned-tab.svg';
import og from 'assets/images/seo/ogp.png';
type SeoProps = {
  title?: string;
  description?: string;
  image?: string;
  locationPathName?: string;
  en?: boolean;
};

const defaultTitle = {
  ja: 'FOURDIGIT Inc. | 株式会社フォーデジット',
  en: 'FOURDIGIT Inc.',
};
const defaultDescription = {
  ja:
    'フォーデジットは、デジタルテクノロジーと人をつなぐ際に欠かせない「デザイン」を提供し、関わる人すべてが心地よい体験を作り上げるデザイン＆テックカンパニーです。デジタル化による企業の変革や、新サービス・新製品の体験設計、Webやアプリの制作・運用まで、幅広いデザインを提供します。',
  en:
    'FOURDIGIT is a design & technology company. We deliver designs that connect digital technology with people’s lives to create experiences that feel good to everyone they touch.',
};
const defaultUrl = 'https://www.4digit.com';

export const SEO: React.FC<SeoProps> = props => {
  const titleLang = {
    ja: props.title ? `${props.title}｜${defaultTitle.ja}` : defaultTitle.ja,
    en: props.title ? `${props.title}｜${defaultTitle.en}` : defaultTitle.en,
  };
  const descriptionLang = {
    ja: props.description ? `${props.description}、${defaultDescription.ja}` : defaultDescription.ja,
    en: props.description ? `${props.description},${defaultDescription.en}` : defaultDescription.en,
  };

  const title = props.en ? titleLang.en : titleLang.ja.replace(/(<br>|<br \/>|<br\/>)/gi, '');
  const description = props.en ? descriptionLang.en : descriptionLang.ja;
  const image = props.image ? props.image : og;

  return (
    <Helmet title={title} bodyAttributes={{ 'data-lang': props.en ? 'en' : 'jp' }}>
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {/* og */}
      {props.locationPathName && <meta property="og:url" content={defaultUrl + props.locationPathName} />}
      <meta property="og:type" content={props.title ? 'article' : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={defaultUrl + image} />

      {/* facebook */}
      <meta property="fb:app_id" content="363718924756760" />

      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={defaultUrl + image} />

      {/* favicon */}
      <link rel="apple-touch-icon" sizes="200x200" href={faviconApple} />
      <link rel="icon" type="image/svg+xml" href={favicon} />
      <link rel="mask-icon" href={faviconSafari} color="#ffffff" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {/* adobe fonts. DNP Shuei Gothic Gin Std */}
      <script type="text/javascript">
        {`
          (function(d) {
            var config = {
              kitId: 'exg7klz',
              scriptTimeout: 3000,
              async: true
            },
            h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
          })(document);
        `}
      </script>
    </Helmet>
  );
};
export default SEO;
