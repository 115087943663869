import styled from '@emotion/styled';
import { css } from '@emotion/core';
import React from 'react';
import FacebookIcon from 'assets/images/icons/sns/icon-facebook.inline.svg';
import NoteIcon from 'assets/images/icons/sns/icon-note.inline.svg';
import WantedlyIcon from 'assets/images/icons/sns/icon-wantedly.inline.svg';
import DribbleIcon from 'assets/images/icons/sns/icon-dribble.inline.svg';
import BehanceIcon from 'assets/images/icons/sns/icon-behance.inline.svg';
import LinkedinIcon from 'assets/images/icons/sns/icon-linkedin.inline.svg';
import Instagram from 'assets/images/icons/sns/icon-instagram.inline.svg';
import Twitter from 'assets/images/icons/sns/icon-twitter.inline.svg';
import colors from 'style/colors';

const snsItem = {
  facebook: {
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/4digit/',
  },
  note: {
    icon: <NoteIcon />,
    link: 'https://note.com/4digit',
  },
  wantedly: {
    icon: <WantedlyIcon />,
    link: 'https://wantedly.com/companies/4digit',
  },
  dribble: {
    icon: <DribbleIcon />,
    link: 'dummy',
  },
  behance: {
    icon: <BehanceIcon />,
    link: 'dummy',
  },
  linkedin: {
    icon: <LinkedinIcon />,
    link: 'dummy',
  },
  instagram: {
    icon: <Instagram />,
    link: 'dummy',
  },
  twitter: {
    icon: <Twitter />,
    link: 'dummy',
  },
} as const;

export type SnsIconType = keyof typeof snsItem;

type SnsIconProps = {
  name: SnsIconType;
  to?: string;
  fill?: boolean;
};

type ContainerProps = {
  fill?: 'fill';
};

const Container = styled.a<ContainerProps>`
  display: inline-block;
  ${({ fill }) =>
    fill === 'fill' &&
    css`
      svg,
      svg use,
      svg g {
        fill: ${colors.black01};
      }
    `};
`;

export const SnsIcon: React.FC<SnsIconProps> = ({ name, to, fill, ...props }) => {
  const sns = snsItem[name];
  return (
    <Container {...props} fill={fill ? 'fill' : undefined} href={to || sns.link} target="_blank">
      {sns?.icon || <FacebookIcon />}
    </Container>
  );
};
