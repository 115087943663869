import React from 'react';

export type LogoProps = {
  type: 'white-full' | 'white-sq';
};

const Logo: React.FC<LogoProps> = (
  props = {
    type: 'white-full',
  }
) => {
  const { type } = props;
  return (
    <>
      {type === 'white-full' && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 17">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#fff"
            d="M33.7 8.5C33.7 4 37.4.4 41.9.4c4.5 0 8.2 3.6 8.2 8.1s-3.7 8.1-8.2 8.1c-4.5 0-8.2-3.6-8.2-8.1zm3.4 0c0 2.7 2.2 4.8 4.8 4.8 2.7 0 4.8-2.2 4.8-4.8 0-2.7-2.2-4.8-4.8-4.8-2.7 0-4.8 2.2-4.8 4.8z"
          />
          <path
            fill="#fff"
            d="M120.1 7.6L114 8v3l2.3-.2c-.8 1.6-2.5 2.7-4.6 2.6-2.8-.2-4.7-2.5-4.5-5.2.2-2.7 2.5-4.7 5.2-4.5 1.6.1 2.9 1 3.7 2.3l3.5-.3c-1.2-3.3-4.5-5.5-8.2-5.2-4.5.3-7.9 4.2-7.6 8.7s4.2 7.8 8.8 7.5c4.5-.3 7.9-4.2 7.6-8.7-.1-.2-.1-.3-.1-.4z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#fff"
            d="M82 .5h6.1c4.2 0 7.5 3.4 7.5 7.5v1c0 4.2-3.4 7.5-7.5 7.5H82v-.1c.5-.4.6-1.3.7-2 .1-1.9.1-3.9.1-5.8 0-2 0-3.9-.1-5.9 0-.7-.2-1.7-.7-2V.5zm5.8 12.9c2.5 0 4.5-2.1 4.5-4.6v-.6c0-2.5-2-4.6-4.5-4.6h-1.9c-.1 1.4-.2 3-.2 4.9 0 1.9.1 3.5.2 4.9h1.9z"
          />
          <path
            fill="#fff"
            d="M101.4.5h-3.3v16h3.3V.5zm24.3 0h-3.2v16h3.2V.5zm-104.2 4v3H20v3.1h1.5v3.7c0 .7-.2 1.7-.7 2.1v.1H25c-.1-1.6-.2-3.6-.2-5.9h4.7V7.4h-4.7c0-1.4.1-2.6.2-3.7h7.8V.5H21.3c.1 1.3.1 2.7.2 4z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#fff"
            d="M79.8 6.1C79.8 3 77.3.6 74.2.6h-6.5v16h3.1v-5H73l3.5 5h3.9v-.1l-3.8-5.3c1.8-1 3.2-2.9 3.2-5.1zm-3.3.4c-.1.7-.5 1.4-1.1 1.7-.8.4-1.6.4-2.4.4h-2.1v-5h3c.6 0 1.3.1 1.8.5.6.6.9 1.5.8 2.4z"
          />
          <path
            fill="#fff"
            d="M61.6 9.3c0 .9 0 2.3-.5 3-.4.6-1.1 1-1.8 1.1h-1.2c-.7-.1-1.4-.5-1.8-1.1-.5-.7-.5-2.1-.5-3V.5h-3.3v10c0 1 .1 2 .5 2.9.4.9 1.1 1.6 1.9 2.2.8.6 1.8.9 2.8 1h2.1c1-.1 2-.4 2.8-1 .8-.6 1.5-1.3 1.9-2.2.4-.9.5-1.9.5-2.9V.5h-3.3l-.1 8.8zM128 .5v3.2h4.3v12.8h3.3V3.7h4.3V.5H128zM0 .5v16h16V.5H0zm7.1 9.7H4.7V4.4h.9c.8.2 1.4.9 1.5 1.7v4.1zm4.5-.1v.3H10v2h-.9c-.8-.1-1.4-.9-1.4-1.7V5.8h1c.7.2 1.3 1 1.3 1.8v.7h.4c.6.1 1.1.6 1.2 1.2v.6z"
          />
        </svg>
      )}
      {type === 'white-sq' && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 88">
          <path
            d="M56.4 81.8l3.7-.3v.2c.2 2.7-1.8 5-4.5 5.2-2.7.2-5.1-1.8-5.2-4.5-.2-2.7 1.9-5 4.6-5.2 2.2-.2 4.2 1.2 4.9 3.1l-2.1.2c-.4-.8-1.2-1.3-2.2-1.4-1.6-.1-3 1.1-3.1 2.7-.1 1.6 1 3 2.7 3.1 1.2.1 2.2-.6 2.7-1.6l-1.4.1v-1.6zm-15.6-4.5h-3.7v.1c.3.2.4.8.4 1.2 0 1.2.1 2.3.1 3.5 0 1.2 0 2.3-.1 3.5 0 .4-.1 1-.4 1.2v.1h3.7c2.5 0 4.5-2 4.5-4.5v-.5c.1-2.5-2-4.6-4.5-4.6zm2.6 5c0 1.5-1.2 2.7-2.7 2.7h-1.1c-.1-.8-.1-1.8-.1-2.9s0-2.1.1-2.9h1.1c1.5 0 2.7 1.2 2.7 2.7v.4zm-30.3-5c-2.7 0-4.9 2.2-4.9 4.8s2.2 4.8 4.9 4.8 4.9-2.2 4.9-4.8-2.1-4.8-4.9-4.8zm0 7.7c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9c1.6 0 2.9 1.3 2.9 2.9S14.8 85 13.1 85zm33.8-7.7h2v9.5h-2zm14.6 0h2v9.5h-2zM.9 81.5H0v1.9h.9v2.3c0 .4-.1 1-.4 1.2v.1H3c-.1-1-.1-2.2-.1-3.5h2.8v-1.9H2.9c0-.8.1-1.5.1-2.2h4.7v-1.9H.8c0 .8.1 1.6.1 2.3v1.7zm33 2.1c1.1-.5 1.9-1.7 1.9-3 0-1.8-1.5-3.3-3.3-3.3h-3.9v9.5h1.8v-3h1.3l2.1 3h2.3v-.1l-2.2-3.1zm0-2.7c-.1.4-.3.8-.7 1-.5.3-.9.3-1.4.3h-1.3v-3h1.8c.4 0 .8.1 1.1.3.4.3.6.8.5 1.4zm-9 1.7c0 .5 0 1.4-.3 1.8-.3.3-.7.6-1.1.6h-.7c-.4 0-.8-.3-1.1-.6-.3-.4-.3-1.3-.3-1.8v-5.2h-2v6c0 .6.1 1.2.3 1.7.3.5.6 1 1.1 1.3.5.3 1.1.5 1.7.6h1.3c.6-.1 1.2-.3 1.7-.6s.9-.8 1.1-1.3c.3-.5.3-1.1.3-1.7v-6h-2v5.2zm39.9-3.4v-1.9H72v1.9h-2.6v7.7h-2v-7.7zM0 0v71.6h72V0H0zm31.8 43.3c0 .1 0 .1 0 0H21V17.4c.4 0 3-.1 4.2.1 3.7.5 6.5 3.8 6.5 7.5v18.3zm20.4-.5v1.5h-7.1c-.1 0-.1.1-.1.1v8.9h-.6c-1.2 0-2.6 0-3.8-.2-3.6-.5-6.3-3.8-6.3-7.4v-22h1.2c1.1 0 2.2 0 3.2.2 3.6.5 6.3 3.8 6.3 7.4v2.9-.1c0 .1.1.1.1.1.6 0 1.2 0 1.9.1 2.7.4 4.9 2.7 5.2 5.4v3.1z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#fff"
          />
        </svg>
      )}
    </>
  );
};

export default Logo;
